define('app/app',['jquery'], function ($) {
    'use strict';

    return {
        init: function () {
            console.log('Start initializing app.js');
            $(document).foundation('topbar', 'reflow');
        }
    };
});
