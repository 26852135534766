(function () {
    'use strict';
    // -- RequireJS config --
    requirejs.config({
        baseUrl: '/assets/js',
        paths: {
            'modernizr': '../lib/modernizr/modernizr',
            'jquery': '../lib/jquery/jquery',
            'moment.timezone': '../lib/moment-timezone/moment-timezone-with-data-2010-2020',
            'moment': '../lib/momentjs/moment',
            'lodash': '../lib/lodash/dist/lodash',
            'foundation': '../lib/foundation/js/foundation'
        },
        shim: {
            'foundation': ['jquery'],
            'modernizr': {
                exports: 'Modernizr'
            }
        }
    });

    requirejs.onError = function (err) {
        console.log(err);
    };

    requirejs(["app/main"]);
})();
define("main", function(){});

