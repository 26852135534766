define('app/main',[
    'jquery',
    'app/app',
    'foundation'
], function ($, app) {
    'use strict';
    $(document).ready(function () {
        console.log('Loaded app/main.js');
        app.init();
    });
});
